.landingContainer {
  height: 90vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
  overflow-x: hidden;
}

.logoImg {
  width: 18%;
  margin-bottom: 30px;
}

.cardPic {
  height: 80%;
  border-radius: 2%;
}

.header {
  color: var(--parchment);
  font-size: 80px;
  font-family: 'made-dillan';
  font-weight: 400;
  margin-bottom: 5px;
}

.subHeader {
  color: var(--parchment);
  font-size: 24px;
  font-family: 'made-dillan';
  margin: 0;
}

.launch {
  color: var(--parchment);
  font-weight: 500;
  margin-top: 20%;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
}

@media (max-width: 500px) {
  .landingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: fit-content;
  }

  .logoImg {
    width: 18%;
    margin-bottom: 30px;
  }

  .cardPic {
    height: 300px;
    border-radius: 2%;
    margin-top: 3rem;
  }

  .header {
    color: var(--parchment);
    font-size: 40px;
    font-family: 'made-dillan';
    font-weight: 400;
    margin-bottom: 5px;
    margin-top: 30px;
  }

  .subHeader {
    color: var(--parchment);
    font-size: 18px;
    font-family: 'made-dillan';
    margin: 0;
    width: 100%;
    text-align: center;
  }

  .launch {
    color: var(--parchment);
    font-weight: 500;
    margin-top: 30px;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 7%;
  }
}
