.demoContainer {
  background-color: var(--grayBlue);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.frameDemo {
  position: relative;
  z-index: 1;
  width: 100%;
}

.phoneScreenDemo {
  width: 40%;
  position: absolute;
  left: 30%;
  top: 6.5%;
  z-index: 0;
}

.phoneContainerDemo {
  position: relative;
  width: 40%;
  margin-left: 5%;
}

@media (max-width: 500px) {
  .demoContainer {
    background-color: var(--grayBlue);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .frameDemo {
    position: relative;
    z-index: 1;
    width: 100%;
  }
  
  .phoneScreenDemo {
    width: 40%;
    position: absolute;
    left: 30%;
    top: 6.5%;
    z-index: 0;
  }
  
  .phoneContainerDemo {
    position: relative;
    width: 100%;
  }
}