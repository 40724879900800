.navBar {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  position: absolute;
  height: 6rem;
  align-items: center;
  top: 0;
  background-color: transparent;
}

.navBar ul {
  display: flex;
  list-style: none;
  font-weight: 500;
  color: var(--parchment);
  width: 100%;
  justify-content: flex-end;
}

.navBar li {
  text-decoration: none;
  margin-right: 3%;
  font-size: 16px;
  font-weight: 500;
  color: var(--parchment);
}

.navBar li a {
  text-decoration: none;
  margin-right: 3%;
  font-size: 16px;
  font-weight: 500;
  color: var(--parchment);
}

.logo {
  font-family: 'made-dillan';
  color: var(--white);
  font-size: 36px;
  margin-left: 3%;
}

.navBar li:hover {
  color: var(--charcoal);
  cursor: pointer;
}

.navBar li a:hover {
  color: var(--charcoal);
  cursor: pointer;
}

@media (max-width: 500px) {
  .navBar {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    height: 6rem;
    align-items: center;
    background-color: transparent;
  }

  .navBar ul {
    display: flex;
    list-style: none;
    font-weight: 500;
    color: var(--parchment);
    width: 100%;
    justify-content: flex-end;
  }

  .navBar li {
    text-decoration: none;
    margin-right: 5%;
    font-size: 16px;
    font-weight: 500;
    color: var(--parchment);
  }

  .navBar li a {
    text-decoration: none;
    margin-right: 5%;
    font-size: 16px;
    font-weight: 500;
    color: var(--parchment);
  }

  .logo {
    font-family: 'made-dillan';
    color: var(--white);
    font-size: 36px;
    margin-left: 3%;
  }

  .navBar li:hover {
    color: var(--parchment);
    cursor: pointer;
  }

  .navBar li a:hover {
    color: var(--parchment);
    cursor: pointer;
  }
}
