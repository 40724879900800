.missionContainer {
  background-color: var(--grayBlue);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
}

.missionBodyContainer {
  text-align: left;
  color: var(--white);
  font-size: 18px;
  font-weight: 500;
}

.frame {
  position: relative;
  z-index: 1;
  width: 100%;
}

.phoneScreen {
  width: 40%;
  position: absolute;
  left: 30%;
  top: 6.5%;
  z-index: 0;
}

.phoneContainer {
  position: relative;
  width: 40%;
  margin-left: 5%;
}

.bulletContainer {
  display: flex;
  flex-direction: column;
}

.bulletRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bullet {
  color: var(--parchment);
  margin-bottom: 5px;
}

.bulletText {
  color: var(--parchment);
  margin-left: 3%;
  font-size: 16px;
  font-weight: 500;
  width: 60%;
}

.bulletText:hover {
  cursor: pointer;
  color: rgb(53, 69, 78);
}

.active {
  color: rgb(53, 69, 78);
}

@media (max-width: 500px) {
  .missionContainer {
    background-color: var(--grayBlue);
    display: flex;
    flex-direction: column;
    margin-top: 20%;
  }

  .missionBodyContainer {
    text-align: left;
    color: var(--white);
    font-size: 18px;
    font-weight: 500;
  }

  .frame {
    position: relative;
    z-index: 1;
    width: 100%;
  }

  .phoneScreen {
    width: 40%;
    position: absolute;
    left: 30%;
    top: 6.5%;
    z-index: 0;
  }

  .phoneContainer {
    position: relative;
    width: 90%;
    margin-left: 5%;
  }

  .bulletContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }

  .bulletRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .bullet {
    color: var(--parchment);
    margin-bottom: 5px;
  }

  .bulletText {
    color: var(--parchment);
    margin-left: 3%;
    font-size: 16px;
    font-weight: 500;
    width: 60%;
  }

  .bulletText:hover {
    cursor: pointer;
    color: var(--parchment);
  }

  .active {
    color: rgb(53, 69, 78);
  }
}
